import "./realizations-page.styles.scss";

const RealizationsPage = () => {
    return (
        <div>
            REALIZATIONS
        </div>
    )
}

export default RealizationsPage;