import "./contact-page.styles.scss";

const ContactPage = () => {
    return (
        <div>
            CONTACT
        </div>
    )
}

export default ContactPage;
