import "./home-offer.styles.scss";


const HomeOffer = () => {
    return (
        <div className="home-offer-container">
            <p>NASZA OFERTA</p>
        </div>
    )
}

export default HomeOffer;