import "./offer-page.styles.scss";


const OfferPage = () => {
    
    return (
        <div className="offer-page-container">
            
        </div>
    )
}

export default OfferPage;